@import "./color.scss";

.Toastify__toast--error {
  background: #ffe5e5 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.3rem 1.2rem #1e36482e;
  border-radius: 0.8rem;
  color: #f77474;
  font-size: 1.5rem;
}

.Toastify__toast--success {
  background: #f1fff0 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.3rem 1.2rem #1e36482e;
  border-radius: 0.8rem;
  color: #46af32;
  font-size: 1.5rem;
}
