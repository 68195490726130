@import "../../styles/color.scss";

.productDetailPage {
  height: 91.4vh;
  background-color: $pages-bg-color;
  padding-top: 2rem;
}

.productDetailContainer {
  display: flex;
  margin-left: 22rem;
  margin-right: 22rem;
  width: 148rem;
  height: 76.9rem;
  background-color: $navbar-bg-color;
  border-radius: 0.8rem;

  .detailLeftSide {
    .detailImage {
      img {
        width: 70rem;
        height: 73.7rem;
        border-radius: 0.8rem;
        margin: 1.5rem;
        object-fit: contain;
      }
    }
  }

  .detailRightSide {
    .rightSideContainer {
      margin-top: 3rem;
      margin-left: 1.5rem;
      .detailTitle {
        font-size: 3.4rem;
        color: $detail-title;
      }
      .detailInfo {
        font-size: 1.5rem;
        color: $input-title-color;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 2.5rem;
        .detailBox {
          display: inline-flex;
          .detailBoxTitle {
            width: 14rem;
            font-weight: bold;
          }
        }
      }
      .detailPrice {
        font-size: 2.5rem;
        font-weight: bold;
        margin-top: 3rem;
      }

      .givenOffer {
        display: flex;
        align-items: center;
        padding: 1rem;
        gap: 0.4rem;
        width: 23rem;
        height: 3.6rem;
        background-color: $pages-bg-color;
        border-radius: 0.8rem;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 1rem;
        span {
          font-weight: normal;
          color: $offer-radio-btn;
        }
      }

      .detailBtn {
        display: flex;
        gap: 1rem;
      }

      .detailDesc {
        font-size: 1.5rem;
        color: $detail-title;
        margin-top: 3rem;
        .descTitle {
          font-size: 1.8rem;
          font-weight: bold;
          color: $input-title-color;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.notFoundProduct {
  max-height: 90vh;
  overflow: hidden;
  img {
    width: 100%;
  }
}
