@import "../../styles/color.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  height: 8rem;
  background-color: $navbar-bg-color;
  padding: 2rem 22rem;

  .navRightSide {
    button {
      color: $navbar-btn-color;
      background-color: $navbar-btn-bg-color;
      margin-left: 1rem;
      border: none;
      border-radius: 0.8rem;
      width: 12.5rem;
      height: 4rem;
      font-weight: bold;
      font-size: 1.5rem;

      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.2rem;
      }
    }
  }
}
