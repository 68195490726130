@import "../../styles/color.scss";

.indexPage {
  background-color: $pages-bg-color;
  padding-bottom: 15rem;
  min-height: 85.2rem;
}
.indexContainer {
  margin-left: 22rem;
  margin-right: 22rem;
  min-width: 57.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .indexBanner img {
    width: 100%;
    margin-top: 2rem;
  }

  .indexCategories {
    display: flex;
    margin-top: 3rem;

    .categoryCard {
      display: flex;
      gap: 3.8rem;
      border-bottom: 1px solid $stick;
      overflow: scroll;

      .categoryName {
        font: normal normal 600 1.8rem Nunito;
        color: $input-title-color;
        cursor: pointer;
        padding-bottom: 1.5rem;
      }
      .categoryName:hover {
        color: $navbar-btn-color;
        border-bottom: 2px solid $navbar-btn-color;
      }

      .activeCategory {
        color: $navbar-btn-color;
        border-bottom: 2px solid $navbar-btn-color;
      }
    }
  }

  .categoryCard::-webkit-scrollbar {
    display: none;
  }

  .indexProducts {
    display: flex;
    justify-content: baseline;
    flex-flow: wrap;
    gap: 1.5rem;
    width: 100%;

    .productCard {
      margin-top: 2rem;
      width: 28rem;
      height: 39.2rem;
      background: $navbar-bg-color 0% 0% no-repeat padding-box;
      border-radius: 0.8rem;

      .productImg {
        display: flex;
        justify-content: center;

        img {
          width: 26rem;
          height: 29.7rem;
          border-radius: 0.8rem;
          margin-top: 1rem;
          object-fit: contain;
        }
      }
      .productInfo {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;
        margin-left: 1rem;
        margin-right: 1rem;

        .productBrand {
          font: normal normal bold 1.5rem Nunito;
          color: $navbar-btn-color;
        }

        .productColor {
          span {
            font: normal normal bold 1.3rem Nunito;
          }
          font: normal normal normal 1.3rem Nunito;
          color: $product-info-color;
        }
      }

      .productPrice {
        margin-left: 10px;
        margin-top: 26px;
        font: normal normal bold 1.8rem Nunito;
        color: $product-info-color;
      }
    }
  }
}
