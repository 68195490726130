@import "../../styles/color.scss";

.formContainer {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  background-color: $form-page-bg-color;
}

.leftSide {
  width: 40%;
  background-image: url("https://i.hizliresim.com/auht5f8.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rightSide {
  width: 60%;

  .rightSideContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .logo img {
      width: 22.4rem;
      height: 7.3rem;
    }

    .forms {
      width: 56.9rem;
      height: 55.4rem;
      margin-top: 5.5rem;
      background-color: $navbar-bg-color;
      border-radius: 0.8rem;
      box-shadow: 0rem 0.3rem 1.2rem #1e36480a;
      display: flex;
      align-items: center;
      justify-content: center;

      .registerLoginForm {
        display: flex;
        flex-direction: column;

        .formTitle {
          text-align: center;
          color: $input-title-color;

          .title {
            font-weight: bold;
            font-size: 3.2rem;
          }

          .smallTitle {
            margin-top: 1rem;
            font-size: 1.5rem;
            font-weight: normal;
          }
        }

        .form {
          margin-top: 3rem;

          .inputContainers {
            margin-top: 1.5rem;

            .inputTitle {
              color: $input-title-color;
              display: block;
              font-weight: normal;
              margin-bottom: 0.5rem;
              font-size: 1.5rem;
              margin-left: 0.5rem;
            }

            .allInput {
              background: $input-bg-color 0% 0% no-repeat padding-box;
              border: none;
              border-radius: 0.8rem;
              padding-left: 1rem;
              outline: none;
              width: 38.9rem;
              height: 4.5rem;
              font-size: 1.6rem;
            }

            .errorInput {
              background: $error-input 0% 0% no-repeat padding-box;
              border: 0.1rem solid $error-input-border;
            }
          }

          .forgotPassword {
            margin-top: 0.5rem;
            text-align: right;
            color: #b1b1b1;
            font: normal 1.2rem Nunito;

            span {
              cursor: pointer;
            }

            .hiddenForget {
              visibility: hidden;
            }
          }

          .submitBtn {
            background: $submit-btn-bg 0% 0% no-repeat padding-box;
            border-radius: 0.8rem;
            width: 38.9rem;
            height: 4.5rem;
            margin-top: 2.5rem;
            font: normal normal bold 1.8rem Nunito;
            color: $navbar-bg-color;
            border: none;
          }
        }

        .otherLink {
          margin-top: 2rem;
          text-align: center;
          font: normal normal normal 1.5rem Nunito;
          span {
            color: $submit-btn-bg;
            font: normal normal bold 1.5rem Nunito;
          }
        }
      }
    }
  }
}
