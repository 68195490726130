@import "../../styles/color.scss";

.soldProduct,
.noBuy {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 23.5rem;
  height: 4.5rem;
  background-color: $sold-product-bg;
  border-radius: 0.8rem;

  color: $sold-product;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 3rem;
}
.noBuy {
  width: 46rem;
  height: 5rem;
}

.buyModalBtn {
  position: relative;
  z-index: 1;
  margin-top: 3rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 23.5rem;
    height: 4.5rem;
    background-color: $navbar-btn-color;
    border-radius: 0.8rem;
    color: $navbar-bg-color;
    font-size: 1.8rem;
    border: none;
    font-weight: bold;
  }
  button:hover {
    background-color: $product-button-hover;
    transition: 0.3s;
  }
}

.buy-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $navbar-btn-color;
  opacity: 0.7;
  z-index: 1000;
}

.buy-modals {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35.5rem;
  height: 17.1rem;
  transform: translate((-50%, -50%));
  background-color: $navbar-bg-color;
  padding: 2rem;
  z-index: 1000;
  box-shadow: 0rem 0.3rem 1.2rem $toast-shadow-bg;
  border-radius: 0.8rem;

  .buyTitle {
    display: flex;
    text-align: center;
    flex-direction: column;
    font-size: 1.5rem;
    color: $detail-title;
    span {
      color: $input-title-color;
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 1.2rem;
    }
  }

  .buyButtons {
    display: flex;
    margin-top: 2.5rem;
    gap: 2rem;

    .closeBuy,
    .buyProduct {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 15rem;
      height: 4.5rem;
      border-radius: 0.8rem;
      font-size: 1.8rem;
      text-decoration: none;
    }
    .closeBuy {
      color: $navbar-btn-color;
      background: $navbar-btn-bg-color 0% 0% no-repeat padding-box;
    }
    .buyProduct {
      color: $navbar-bg-color;
      background: $navbar-btn-color 0% 0% no-repeat padding-box;
    }
  }
}
