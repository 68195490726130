@import "../../color.scss";

@media only screen and (max-width: 1200px) {
  .formContainer {
    .leftSide {
      display: none;
    }
    .rightSide {
      width: 100%;

      .rightSideContainer {
        .logo img {
          width: 14.9rem;
          height: 4.8rem;
        }

        .forms {
          width: 35.5rem;
          height: 43.5rem;
          margin-top: 2.4rem;

          .registerLoginForm {
            .form {
              margin-top: 3rem;

              .inputContainers {
                .inputTitle {
                  color: $input-title-color;
                  display: block;
                  font-weight: normal;
                  margin-bottom: 0.5rem;
                  font-size: 1.5rem;
                  margin-left: 0.5rem;
                }

                .allInput {
                  background: $input-bg-color 0% 0% no-repeat padding-box;
                  border: none;
                  border-radius: 0.8rem;
                  padding-left: 0.5rem;
                  outline: none;
                  width: 31.5rem;
                  height: 4.5rem;
                }
              }

              .forgotPassword {
                margin-top: 0.5rem;
              }

              .submitBtn {
                width: 31.5rem;
                height: 4.5rem;
                margin-top: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
