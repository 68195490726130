$navbar-bg-color: #ffffff;

$navbar-btn-color: #4b9ce2;

$navbar-btn-bg-color: #f0f8ff;

$form-page-bg-color: #fbfbfb;

$input-bg-color: #f4f4f4;

$input-title-color: #525252;

$submit-btn-bg: #4b9ce2;

$error-input: #fff2f2;

$error-input-border: #f77474;

$error-toast-bg: #ffe5e5;

$toast-success: #f1fff0;

$toast-shadow-bg: #1e36482e;

$product-info-color: #3e3e3e;

$stick: #e0e0e0;

$pages-bg-color: #f2f2f2;

$detail-title: #555555;

$sold-product: #faad60;

$sold-product-bg: #fff0e2;

$offer-radio-btn: #b1b1b1;

$product-button-hover: #07bc0daf;
